
  .form-control {
    //prevent iPhone from zooming in when clicking an input that has a font-size smaller then 16px
    font-size: 16px;

  }

  .has-error {
    .help-block,
    .control-label,
    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline {
      //Bootstrap makes all these element red if the form-group is in error state
      color: inherit;
    }
    //errors, triggered by javascript validation
    label.has-error {
      @extend .help-block; //inherit margin from help-block
      font-weight: normal;
      color: $state-danger-text;
    }
  }
  p.has-error {
    color: $state-danger-text;
  }


  // remove 'X' in IE
  input::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  label {
    font-weight: normal;
  }

