.header-container {
  background-color: $white;
}

.navbar-top {
  @include media-breakpoint-up(lg) {
    min-height: 100px;
    justify-content: center;

    .navbar-collapse {
      flex-grow: 0;
    }

    .navbar-brand {
      position: absolute;
      left: 0;
    }
  }

  .nav-link {
    font-size: 1.1rem;
  }
}

.navbar-toggler {
  border: 0;
}

.navbar-light {
  .navbar-toggler-icon {
    // Extracted from design (using Avocode)
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDIwIDE1Ij48Zz48Zz48Zz48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiM1N2I0NDMiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS1taXRlcmxpbWl0PSIyMCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNMSAyaDE4Ii8+PC9nPjxnPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzU3YjQ0MyIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjIwIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0xIDhoMTgiLz48L2c+PGc+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNTdiNDQzIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2UtbWl0ZXJsaW1pdD0iMjAiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTEgMTRoMTgiLz48L2c+PC9nPjwvZz48L3N2Zz4=");
  }
}

img.header-logo {
  @include media-breakpoint-down(md) {
    transform: scale(0.75);
    transform-origin: center left;
  }
}

.content-header {
  h1 {
    font-size: 2.375rem;
    margin: 10px;

    &.content-header-subtitle {
      font-size: 1.75rem;
    }
  }
}