@include media-breakpoint-down(md) {
  body > .container {
    padding: 0;
  }
}

.container-spacing {
  padding-top: (40rem/16);
  padding-bottom: (16rem/16);
  @include media-breakpoint-up(sm) {
    padding-top: (50rem/16);
    padding-bottom: (50rem/16);
  }
}

.content-wrapper, .content-header-wrapper {
  padding: 0.5rem;

  @include media-breakpoint-up(sm) {
    padding: 1.5rem;
  }

  @include media-breakpoint-up(md) {
    padding: 3rem;
  }
}