.card {
  .card-title {
    color: theme-color("primary");
  }

  .card-text {
    line-height: 1.33rem;
    font-size: 1rem;
  }

  .card-link {
    font-size: 1rem;
  }
}

.card-group-equal-height-my-3 {
  .card {
    height: calc(100% - 1rem);
  }
}