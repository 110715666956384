$toc-link-hover-focus-color: darken(theme-color("primary"), 7.5%);

.toc-heading {
    @extend .h5;
    height: 40px;
    margin-left: 30px;
    //margin-bottom: 5px;
    // display: inline-block;
    //color: theme-color("primary");
}

.toc-heading::before {
    margin-left: 16px;
}

.toc {
    overflow-y: auto;
  }

  .toc > .toc-list {
    overflow: hidden;
    position: relative;

    li {
      list-style: none;
    }
  }

  .toc-list {
    margin: 0;
    padding-left: 10px;
  }

  a.toc-link {
    color: $body-color;
    font-size: .875rem;
    padding: 0;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    white-space: nowrap;
    display: block;
    padding: 0.5rem 1rem;
    height: 100%;
  }

  .is-collapsible {
    max-height: 1000px;
    overflow: hidden;
    transition: all 300ms ease-in-out;
  }

  .is-collapsed {
    max-height: 0;
  }

  .is-position-fixed {
    position: fixed !important;
    top: 0;
  }

  .is-active-link {
    // font-weight: 700;
    color: theme-color("primary");
  }

  .toc-link::before,
  .toc-heading::before {
    background-color: #EEE;
    content: ' ';
    display: inline-block;
    height: inherit;
    left: 0;
    margin-top: -1px;
    position: absolute;
    width: 2px;
  }

  .is-active-link::before {
    background-color: #54BC4B;
  }