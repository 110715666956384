// Native select
select {
  &.select-full-width {
    width: calc(100% - 2rem);
  }

  &.content-toc {
    background-color: $white;
    height: 2.5rem;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.25);
    text-indent: 1rem;
  }
}