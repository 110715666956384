//Note: The Effra webfont is purchased by LSN (Anita Nouwens) from https://www.daltonmaag.com/library/effra
@font-face {
  font-family: 'effra';
  src: url('#{$font-path}/Effra_W_Rg.woff2') format('woff2'),
  url('#{$font-path}/Effra_W_Rg.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'effra';
  src: url('#{$font-path}/Effra_W_Bd.woff2') format('woff2'),
  url('#{$font-path}/Effra_W_Bd.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}
