$navlink-hover-focus-color: darken(theme-color("primary"), 7.5%);

nav {
    &.navbar-light {
        .nav-item.active {
            .nav-link {
                @include hover-focus(){
                    color: $navlink-hover-focus-color;
                }
            }
        }
    }

    &.navbar-content-spy {
        .nav-link {
            color: $body-color;
            font-size: .875rem;

            &.active {
                color: theme-color("primary");
            }

            @include hover-focus() {
                color: $navlink-hover-focus-color;
            }
        }

        > .nav {
            border-left: 1px solid #E2E1E6;
            > a {
                margin-left: -1.5px;
                text-indent: 1.5px;

                &.active {
                    border-left: 2px solid theme-color("primary");
                }
            }
        }
    }

    .nav-parts {
        flex-wrap: nowrap;
        overflow-x: auto;

        a.nav-link {
            color: theme-color("primary");
            padding: 0;
            padding-bottom: 0.25rem;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            font-size: 1rem;
            white-space: nowrap;
            
            &.active {
                border-bottom: 1px solid theme-color("primary");
            }

            @include hover-focus() {
                color: $navlink-hover-focus-color;
            }
        }
    }
}