.jumbotron {
  &.jumbotron-cover {
    background-size: cover;
  }

  &.jumbotron-home {
    background-position: center center;

    h1 {
      font-size: 4.5rem;
    }

    @include media-breakpoint-down(sm) {
      min-height: 150px;

      h1 {
        font-size: 1.5rem;
      }
    }

    @include media-breakpoint-up(sm) {
      min-height: 150px;

      h1 {
        font-size: 2rem;
      }
    }

    @include media-breakpoint-up(md) {
      min-height: 250px;

      h1 {
        font-size: 2.5rem;
      }
    }

    @include media-breakpoint-up(lg) {
      min-height: 325px;

      h1 {
        font-size: 3.5rem;
      }
    }

    @include media-breakpoint-up(xl) {
      min-height: 400px;

      h1 {
        font-size: 4rem;
      }
    }

    @media (min-width: 1500px) { 
      h1 {
        padding-right: 7.5vw;
        font-size: 4.5em;
      }
    }
  }

  .jumbotron-circle {
    background-image: url('#{$image-path}/jumbotron-qander-effect.png');
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include media-breakpoint-up(md) {
      background-position-x: -200px;
    }

    @include media-breakpoint-up(lg) {
      background-position-x: -150px;
    }
  }

  .jumbotron-circle-small {
    background-image: url('#{$image-path}/jumbotron-qander-effect-small.png');
    background-repeat: no-repeat;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 153px;

    @include media-breakpoint-up(sm) {
      width: 100px;
    }
  }
}